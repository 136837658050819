html, body {
	padding: 0px;
	margin: 0px;
}

html, body, #root {
	height: 100%;
}

a {
    color: unset;
    text-decoration: unset;
}

body .MuiButtonBase-root {
	line-height: 24px;
}

.clickable {
	cursor: pointer;
}

.page-icon {
  transform: scale(8);
  margin: 100px;
}

.error-page {
	text-align: center;
}

.notify-close-button {
	margin-top: 5px;
	cursor: pointer;
}

.page-icon-shadow {
  transform: scale(8);
  margin: 100px;
  position: absolute;
  top: -10px;
  left: calc(50% - 90px);
  opacity: 0.2;
}

.kiosk-offline-center {
    position: absolute;
    top: 50%;
    left: 50%;
	width: 0;
    height: 0;
}

.theme-light .kiosk-offline-box {
	background: rgba(255,255,255,0.9);
}

.theme-dark .kiosk-offline-box {
	background: rgba(32,33,36,0.95);
}

.kiosk-offline-box {
    width: 600px;
    height: 300px;
    position: relative;
    top: -150px;
    left: -300px;
	box-shadow: 0px 0px 150px  rgba(0,0,0,0.5);
	background: #FFF;
	padding: 20px;
    box-sizing: border-box;
	text-align: center;
	overflow: hidden;
}

.kiosk-offline-box .loaddot {
    display: inline-block;
    width: 5px;
    height: 5px;
    position: absolute;
    top: 0px;
    left: 0px;
    background: black;
    border-radius: 20px;
	animation-name: offlineAnim;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}
.theme-dark .kiosk-offline-box .loaddot {
	background: white;
}
.kiosk-offline-box .loaddot.d2 { animation-delay: 0.05s; }
.kiosk-offline-box .loaddot.d3 { animation-delay: 0.10s; }
.kiosk-offline-box .loaddot.d4 { animation-delay: 0.15s; }
.kiosk-offline-box .loaddot.d5 { animation-delay: 0.20s; }

@keyframes offlineAnim {
	0% { left: -50px; }
	100% { left: 650px; }
}

.kiosk-offline-box button.offline-again {
	margin-top: 30px;
}

.kiosk-offline-box .offline-menu {
	position: absolute;
    top: 0px;
    right: 0px;
    z-index: 999;
	
}

.theme-light .kiosk-offline-box .offline-menu>button {
	color: #BBC;
}

.theme-dark .kiosk-offline-box .offline-menu>button {
	color: #777;
}

.kiosk-offline-box h3 {
	transform: scale(2);
	margin: 20px;
}

.dp2-login, .d2-login-wrapper {
	background: #EEE;
	overflow: auto;
	overflow-x: hidden;
	height: 100%;
}

.d2-login-wrapper {
	position: fixed;
	left: 0px;
	right: 0px;
	top: 0px;
	z-index: 1300;
}

@keyframes outLoginAnimation {
  0% {
    top: 0%;
  }
  100% {
    top: -150%;
  }
}

@keyframes inLoginAnimation {
  0% {
    top: -150%;
  }
  100% {
    top: 0%;
  }
}

.theme {
	overflow: scroll;
	overflow-x: hidden;
	/*height: 100%;*/

	height: calc(100% - 64px);
	position: relative;
	top: 64px;
}

.dp2-login .login-form {
	float: right;
    min-height: 100%;
	padding: 40px;
	width: 33%;
	min-width: min(500px, 100%);
	max-width: min(600px, 100%);
	background: #FFF;
	box-shadow: 0px 0px 150px  rgba(0,0,0,0.5);
	box-sizing: border-box;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	row-gap: 40px;
}

.dp2-login .main-form form {
	display: flex;
	flex-direction: column;
	row-gap: 8px;
}

.dp2-login .main-form form button {
	margin-top: 8px;
}

.dp2-login.offline {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	border: 0;
	min-width: 600px;
	overflow: auto;
}

.dp2-login .login-form h1,
.kiosk-offline-box h1 {
	font-size: 40px;
}

.dp2-login .login-form h2,
.kiosk-offline-box h2 {
	font-size: 32px;
}

.dp2-login .logo {
	width: 200px;
	float: left;
	margin: 20px;
}

.dp2-login form>div>label>div {
	width: 100%;
}

.dp2-login .logo {
	position: absolute;
}

.dp2-login .login-about {
    margin-top: 20px;
	text-align: right;
}

.dp2-login .custom-login-instruction {
	margin: 20px 0px;
}

.dp2-login .mini-logo-powered-by {
	height: 45px;
    float: left;
	margin-left: 3px;
}

.dp2-login .other-options tr {
	display: flex;
	flex-wrap: wrap;
	column-gap: 8px;
    row-gap: 8px;
}

.dp2-login .other-options td {
	flex-grow: 1;
	flex-basis: 45%;
}

.dp2-login-animation-container {
	overflow: auto;
    height: 100%;
    left: 600px;
    position: relative;
	transition: left 0.4s ease-out;
}

.loaded .dp2-login-animation-container { 
	left: 0px;
}

.theme-light .dp2-login .login-form {
	background: rgba(255,255,255,0.9);
}

.theme-dark .dp2-login .login-form {
	background: rgba(32,33,36,0.95);
}

.tree {
	padding: 10px 10px;
	border: 1px solid rgba(52, 145, 238, 0.15);
}
.tree {
	border: none;
	padding-left: 0 !important;
}

.theme-dark .tree {
	background-color: #121212;
    background-image: linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09));
	border: 1px solid rgba(52, 145, 238, 0.15);
}

.theme-light .tree {
	background: rgba(153, 158, 162, 0.05);
	box-shadow: 2px 2px 5px 0 #c7c7c7;
}

.tree .kiosks {
	position: relative;
	/*margin-left: 15px;*/
	/*margin-left: 20px;*/
	padding: 5px;
}

#root>.theme.theme-dark {
	background: #222;
}

.tree-content .MuiBox-root {
    padding: 0px;
}


.branch input[type="checkbox"] {
	opacity: 0;
	position: absolute;
	left: 14px;
	top: 9px;
}

.branch:not(.childless) input[type="checkbox"] {
	cursor: pointer;
}

.branch.childless input[type="checkbox"] {
	display: none;
}
.branch input[type="checkbox"] + .title, .tree span.kiosk-title {
	position: relative;
	border-radius: 2px;
	margin-left: 33px;
}

body .tree span.kiosk-title {
	margin-left: 15px;
}

.branch input[type="checkbox"]:checked ~ .branch,
.branch input[type="checkbox"]:checked ~ .kiosks,
.branch input[type="checkbox"]:checked ~ .add-child {
    display: none;
}

.branch input[type="checkbox"]+.title:before {
	content: "−";
	position: absolute;
	left: -20px;
	pointer-events: none;
	background: rgba(192,192,192,0.5);
	display: inline-block;
    padding: 0px 2px;
    line-height: 14px;
    top: 4px;
	border-radius: 2px;
}

.branch:not(.childless) input[type="checkbox"]:hover + .title:before {
	background: #202241;
	color: white;
}

.tree.mode-tags .branch .action.delete {
	display: none;
}

.branch input[type="checkbox"] + .title::before {
	top: 12px;
}

.kioskInfo tr td:nth-child(1) {
	font-weight: bold;
	min-width: 180px;
}

.branch input[type="checkbox"]:checked+.title:before {
	content: "+";
}

.branch.childless input[type="checkbox"],
.branch.childless input[type="checkbox"]+.title:before {
	display: none;
}

.tree .title {
	max-width: calc(100% - 25px); /* minus input width */
	margin-left: 10px;
	padding-top: 0;
	padding-bottom: 0;
	line-height: 100%;
	min-height: unset;
	height: 36px;
}

.tree > .branch > .title .action.delete {
	visibility: hidden;
}

.tree .title .index {
	visibility: hidden;
	position: absolute;
	margin-right: 0;
}

.list.modal-disable .action.delete,
.tree.modal-disable .action.delete,
.tree.modal-disable .action.external-action {
	display: none;
}

.tree .title .drag {
	margin-right: 0.1em;
	position: absolute;
	left: -50px;
}

body .crud-button {
	box-sizing: content-box;
}

.tree .childless .title .drag {
	left: -17px;
}

.tree .childless .title {
	max-width: calc(100% - 2px);
}

.tree.mode-tags .branch .action,
.tree.mode-kiosks .branch .action {
	display: unset;
}

.tree.mode-tags .branch .action.delete,
.tree.mode-kiosks .branch .action.delete {
	display: none;
}
.title > p {
	display: inline-block;
	cursor: pointer;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.tree-content.paged {
	padding-right: 10px;
}

modal-form .tree .title > p {
	max-width: 230px;
}

.list .title > p {
	max-width: calc(100% - 60px); /* minus menu icon */
}

.title > .MuiIconButton-root {
	vertical-align: top;
}

.title.selected > p {
	font-weight: 600;
}

.tree,
.list {
	text-align: left;
}

.tree .MuiIconButton-root,
.list .MuiIconButton-root {
	padding: .25rem;
}

.tree .MuiIconButton-root > svg,
.list .MuiIconButton-root > svg {
	opacity: 0.4;
}

.MuiCard-root {
	/* max-width: 350px; */
	min-width: min(35vw, 350px);
}

.dashboard .tree {
	/*height: 800px;*/
	/*height: calc(100vh - 220px);*/
	min-height: calc(100vh - 220px);
	height: 100%;
	overflow: hidden;
	padding: 0px;
	position: relative;
}

.dashboard .tree .tree-search {
	margin: 10px;
	width: calc(100% - 20px);
}

.dashboard .tree .MuiInputBase-root {
    margin: 0px;
}

.dashboard .tree .tree-content {
    overflow: auto;
    /*height: 720px;*/
	height: calc(100% - 80px);
    width: 100%;
	position: absolute;
	bottom: 0px;
}
.dashboard .tree .tree-content.paged {
    /*height: 673px;*/
	height: calc(100% - 127px);
}

.tree .title.clickable:hover,
.tree .kiosk-title.clickable:hover {
	background: rgba(192,192,192,0.5);
}

.tree .branch {
	position: relative;
	padding: 5px;
	margin-left: 20px;
}

.tree .branch {
	padding: 0;
}

.branch.childless {
	padding-left: 24px;
}

.branch:before {
	content: "";
	border-top: 1px solid rgba(192,192,192,0.5);
	display: inline-block;
	line-height: 1px;
	width: 10px;
	position: absolute;
	top: 15px;
	left: 0px;
}

.tree .branch:before {
	top: 18px;
}

.tree .branch.childless:before {
	width: 21px;
}

.kiosk-status {
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-right: 8px;
	border-radius: 6px;
	border: 1px solid rgba(255,255,255,0.35);
}

.kiosk-color.red,
.kiosk-color.problem,
.kiosk-color.error,
.kiosk-toggle.Mui-selected.error,
.kioskDot.error::before {
	background-color: #d92a55;
	color: #d92a55;
	z-index: 10;
}

.kiosk-color.yellow,
.kiosk-color.warning,
.kiosk-toggle.Mui-selected.warning,
.kioskDot.warning::before {
	background-color: orange;
	color: orange;
	z-index: 10;
}

.kiosk-color.green,
.kiosk-color.online,
.kiosk-toggle.Mui-selected.online,
.kioskDot.online::before {
	background-color: #00B0A6;
	color: #00B0A6;
	z-index: 9;
}
.kiosk-color.gray,
.kiosk-color.offline,
.kiosk-toggle.Mui-selected.offline,
.kioskDot.offline::before {
	background-color: #999;
	color: #999;
	z-index: 8;
}
.kiosk-color.blue,
.kiosk-color.black,
.kiosk-color.unknown,
.kiosk-toggle.Mui-selected.unknown,
.kioskDot.unknown::before {
	background-color: #202241;
	color: #202241;
	z-index: 7;
}

.kiosk-toggle.Mui-selected {
	color: #fff !important;
}

.tree .branch:after,
nopeDISABLED .tree .kiosks:after {
	content: "";
	border-left: 1px solid rgba(192,192,192,0.5);
	display: inline-block;
	height: 100%;
	position: absolute;
	width: 1px;
	left: 0px;
	top: 0px;
}

body .tree .kiosks:after {
	top: -17px;
	left: -4px;
}

.tree .branch:last-child:after,
nopeDISABLED .tree .kiosks:last-child:after {
	height: calc(100% - 18px);
}

span.file-invalid {
	position: relative;
}

span.invalid-icon {
	position: absolute;
	top: 8px;
	left: 18px;
	display: inline-block;
	color: white;
	zoom: 0.6;
	width: 32px;
	height: 32px;
}

.errorbar-wrapper {
	background: red;
	color: white;
	font-size: 14px;
	line-height: 26px;
	padding: 5px;
	display: flex;
	margin-top: -2px;
}

div[class^='fileIcon']:has(span.file-invalid){
	opacity: 1;
}

div[class^='fileIcon']:has(span.file-invalid) {
	opacity: 1;
}

.errorbar-wrapper span {
	margin-left: 10px;
}

.errorbar-wrapper svg {
	margin: 2px;
}
.chonky-gridContainer span.invalid-icon {
	left: unset;
	bottom: unset;
	top: -28px;
	right: -68px;
	zoom: 1;
}

span.invalid-icon svg {
	animation: pulse-invalid-file 2s infinite;
	background: rgba(255, 0, 0, 1);
	border-radius: 20px;
	overflow: visible;
	padding-bottom: 2px;
	box-shadow: 0px 0px 3px red;
}

@keyframes pulse-invalid-file {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.5);
	}

	100% {
		transform: scale(1);
	}
}

.tree.mode-tags > .tree-content > div > div.title > div.action > .star-wrapper {
	display: none;
}

.branch .title .desc {
	opacity: 0.65;
	font-style: italic;
	font-size: 0.75rem;
	line-height: 1rem;
	position: relative;
    bottom: 1px;
}

.branch .title p {
	position: relative;
	top: 2px;
}

.user-strip {
	display: flex;
}

.userInfo tr {
	min-height: 40px;
	line-height: 40px;
}

.dp2-login-video video {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	object-fit: cover;
}

.user-strip img.user-avatar {
	margin-right: 6px;
}

.MuiList-root.context-menu {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.password-visibility-toggle {
    margin: 0 -30px 0 0;
    padding: 0 2px;
    display: inline-flex;
    position: relative;
    left: -30px;
    top: 8px;
    background: white;
}

img.profile-avatar {
	width: 150px;
	height: 150px;
}

img.user-avatar {
	width: 32px;
	height: 32px;
	border-radius: 16px;
	margin: -6px -4px;
}

body .menu-label {
	pointer-events: none;
	cursor: default;
	font-weight: bold;
}

.search-main-wrapper .MuiInputBase-root {
	margin-bottom: 0;
}

.nav-link {
	display: block;
}

.nav-link.disabled {
	cursor: default;
    opacity: 0.35;
    pointer-events: none;
}

.theme-dark a.nav-link.activated>div {
	background: rgba(255,255,255,0.2);
}
.theme-light a.nav-link.activated>div {    
	background: rgba(0,0,0,0.1);
}

.chonky-toolbarLeft .MuiOutlinedInput-notchedOutline {
	border: 1px solid rgba(128,128,128, 0.5);
}

button.chonky-baseButton {
	line-height: 1.25 !important;
}

.chonky-toolbarRight {
	flex-wrap: wrap !important;
}

p.chonky-infoText {
	/*line-height: 1.25 !important;*/
}

.search-dropdown li.MuiAutocomplete-option {
	display: inline-block;
	width: 500px;
	padding: 0px;
}

.search-no-results {
	display: inline-block;
	margin: 30px;
}

.branch .title .star-wrapper {
	line-height: 1px;
	height: 1px;
	display: none;
}
.branch  .title:hover .star-wrapper,
.branch  .title  .star-wrapper.active,
.tree.mode-tags .branch  .title  .star-wrapper {
	display: inline-block;
}

.tree.mode-tags > .tree-content > .branch > .title > .star-wrapper {
	display: none;
}

@keyframes search-highlight-file-anim {
	0% {transform: scale(100%);}
  50% {transform: scale(90%);}
  1000% {transform: scale(100%);}
}

.search-highlight-file {
	position: relative;
}

.search-highlight-file:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 15px solid rgba(255,255,0,0.66);
    border-radius: 15px;
	animation-name: search-highlight-file-anim;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	z-index: 9999;
}

.search-dropdown {
    z-index: 1200;
    padding-top: 10px;
    text-align: center;
    width: calc(100vw - 200px);
    box-shadow: 0px 0px 1000px black;
}

.search-dropdown.sidebar-opened {
    width: calc(100vw - 500px);
}

.search-result {
	text-align: left;
}

.search-result table {
	padding: 0;
	margin: 0;
}

.search-result-title {
	font-weight: bold;
	text-overflow: ellipsis;
    white-space: nowrap;
    width: 420px;;
    overflow: hidden;
}

.search-result-desc {
	text-overflow: ellipsis;
    white-space: nowrap;
    width: 420px;;
    overflow: hidden;
}

.search-dropdown .MuiAutocomplete-groupLabel {
    text-transform: uppercase;
    font-size: large;
	background: none;
}

.search-icon-box {
	width: 50px;
    text-align: center;
}
.kiosk-search-result-icon {
	color: white;
    border-radius: 100px;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 52px;
}
.kiosk-search-result-icon.error {
	background-color: #d92a55;
}
.kiosk-search-result-icon.warning {
	background-color: orange;
}
.kiosk-search-result-icon.offline {
	background-color: #999;
}
.kiosk-search-result-icon.online {
	background-color: #00B0A6;
}
.kiosk-search-result-icon.unknown {
	background-color: #202241;
}

@keyframes error-page-animation {
  from {opacity: 0; margin-top: 100px; }
  to {opacity: 1; margin-top: 0px; }
}

.error-page {
	opacity: 0;
	animation-delay: 2s;
	animation-name: error-page-animation;
	animation-duration: 1s;
	animation-fill-mode: forwards;
}

body .settings-page.kiosk-option-buttons .MuiButton-root {
    width: 200px;
    height: 200px;
    display: inline-block;
    margin-right: 10px;
	margin-top: 10px;
}

body .settings-page span.MuiButton-startIcon {
    display: block;
    margin: 0px;
}
body .settings-page span.MuiButton-startIcon svg {
	font-size: 50px;
}
body .settings-page {
	margin-top: 20px;
}

div.tree .kiosk .kiosk-title {
	white-space: nowrap;
}

.color-picker .preview-box {
	float: right;
}

.color-picker .preview-box.dark {
	color: rgba(255, 255, 255, 0.8);
}
.color-picker .preview-box.light {
	color: rgba(0, 0, 0, 0.8);
}

.sketch-picker.dark {
	background: #333 !important;
}
.sketch-picker.light {
	background: #FFF !important;
}

.sketch-picker.dark label {
	color: #FFF !important;
}
.sketch-picker.light label {
	color: #333 !important;
}

.color-picker .content {
	position: relative;
}

.color-picker .hex-span {
	height: 20px;
    display: inline-block;
    position: absolute;
    top: calc(50% - 10px);
}

.stats .MuiAccordionSummary-content.Mui-expanded {
	visibility: hidden;
}

.checker-board {
	background-color: lightgray;
	background-image: linear-gradient(45deg, gray 25%, transparent 25%), linear-gradient(-45deg, gray 25%, transparent 25%), linear-gradient(45deg, transparent 75%, gray 75%), linear-gradient(-45deg, transparent 75%, gray 75%);
	background-size: 20px 20px;
	background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

@keyframes check-animation {
	from {
		width: 0%;
	}

	to {
		width: 100%;
	}
}

.check-anim, .check-anim-mask {
	display: inline-block;
	height: 20px;
	width: 20px;
	overflow: hidden;
	text-align: left;
}
.check-anim-mask {
	animation: check-animation 1s linear;
}

.modal-window-file-explorer > .MuiBox-root {
	width: round(down, 90vw, 2px);
	height: round(down, 90vh, 2px);
}
@media (min-width: 600px) {
	.modal-window-file-explorer > .MuiBox-root {
		width: round(down, 70vw, 2px);
	}
}

.theme.fullscreen .MuiDrawer-root,
.theme.fullscreen header {
	display: none;
}

.theme.fullscreen {
	overflow: hidden;
}